<template>
  <v-card>
    <div class="text-center">
      <v-card-title>
        Consultar Processo
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="processos"
          :items-per-page="5"
          class="elevation-1"
          :loading="loadingLawsuits"
          loading-text="Carregando processos"
        >
          <template v-slot:item.acoes="{ item }">
            {{ item.action.name }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn icon :to="{ path: 'processos/' + item.id }">
              <v-icon color="green"> fas fa-eye </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import instanceAxios from "../../plugins/axios";
export default {
  data() {
    return {
      loadingLawsuits: false,
      acoes: [],
      headers: [
        {
          text: "Titulo",
          align: "start",
          value: "title",
        },
        { text: "Tipo de ação", value: "acoes" },
        { text: "Status", value: "status" },
        { text: "Valor da causa", value: "cause" },
        { text: "Ações", value: "actions" },
      ],
      processos: [],
    };
  },

  methods: {
    async getAcoes() {
      this.loadingAcoes = true;

      const { data } = await instanceAxios.get("actions");

      this.acoes = data;

      this.loadingAcoes = false;
    },
    getAcao(acaoId) {
      let acao = this.acoes.find((element) => element.id == acaoId);

      return acao.name;
    },
    mostrarProcesso() {
      this.$router.push("");
    },

    async getLawsuits() {
      this.loadingLawsuits = true;
      
      const { data } = await instanceAxios.get("lawsuits");

      this.processos = data.data;

      this.loadingLawsuits = false;
    },
  },

  mounted() {
    this.getAcoes();
    this.getLawsuits();
  },
};
</script>

<style></style>
